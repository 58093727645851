import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import { AllTagDefinition } from "../params/all-tag-difinition"

// tagInfosには、配列が入ります。
// type TagInfos[] = {
//   name: string; // 表示名
//   slug: string; // タグのslug（tagDefinitionで定義している場合）
//   totalCount: number; // タグが設定された記事数
// }
// 引数のtagsには、タグの情報を取得したいタグの名前（日本語）を入れます。それにslugとtotalCountを付けて返してくれるイメージです。

const GetTagInfos = (tags) => {
  const data = useStaticQuery(graphql`
    {
      tagsGroup: allMdx {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  let tagInfos = []

  // タグの英名を取得する（gatsby-node.jsと同じ処理である必要があります）
  tags.map((tag) => {

    // タグの英名を取得する（gatsby-node.jsと同じ処理である必要があります）============
    // この処理は廃止。
    // const tagDefinition = AllTagDefinition.filter(
    //   (item) => item.name === tag.fieldValue
    // )
    // const tagSlug =
    //   tagDefinition.length === 0 ? tag.fieldValue : tagDefinition[0].slug // 定義に無かった場合（filterした結果が空配列）は日本語のままslugにする。
    // =====================================================================

    const tagSlug = _.kebabCase(tag)
    console.log(tag)
    const totalCountRecord = data.tagsGroup.group.filter(
      (item) => item.fieldValue === tag
    )


    const tagTotalCount = totalCountRecord[0].totalCount
    tagInfos.push({ name: tag, slug: tagSlug, totalCount: tagTotalCount })
  })

  return tagInfos
}

export default GetTagInfos
