import { TimeIcon } from "@chakra-ui/icons"
import { Center, Flex, Text } from "@chakra-ui/react"
import * as React from "react"

const ContentsDate = (props) => {
  const { created, modified } = props
  
  return (
    <Flex justifyContent={{ base: "left", lg: "right" }}>
      <Center mr={3}>
        <TimeIcon w={3} h={3} />
      </Center>
      <Text fontSize="sm">{created}</Text>
      <Text fontSize="sm">（最終更新： {modified || created}）</Text>
    </Flex>
  )
}

export default ContentsDate
