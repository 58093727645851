// タグアイコン

import { Tag, TagLabel } from "@chakra-ui/react"
import * as React from "react"
// import { AiFillTag } from 'react-icons/ai'
import { Link } from "gatsby"

const MyTagLabel = (props) => {
  const { children, path , size="sm", colorScheme="orange"} = props
  return (
    <Link to={`/${path}`}>
    <Tag
      valiant="solid"
      colorScheme={colorScheme}
      boxShadow="sm"
      borderRadius="none"
      size={size}
      _hover={{color: "red.500"}}
      fontSize="0.7rem"
      fontWeight="normal"
    >
      {/* <TagLeftIcon as={AiFillTag}></TagLeftIcon> */}
      <TagLabel>{children}</TagLabel>
    </Tag>
    </Link>
  )
}

export default MyTagLabel
