import { Wrap, WrapItem } from "@chakra-ui/react"
import * as React from "react"
import GetTagInfos from "../getTagInfos"
import MyTagLabel from "../atom/my-tag-label"

const TagLabelHstack = (props) => {
  const { tags, size = "md", colorScheme = "gray" } = props
  const tagInfos = GetTagInfos(tags)

  console.log(tagInfos)
  return (
    <Wrap spacing={3}>
      {tagInfos.map((tagInfo) => (
        <WrapItem>
          <MyTagLabel
            path={`tags/${tagInfo.slug}`}
            size={size}
            colorScheme={colorScheme}
          >{`${tagInfo.name} (${tagInfo.totalCount})`}</MyTagLabel>
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default TagLabelHstack
