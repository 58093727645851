import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import Profile from "../components/organisms/profile"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import PostCard from "../components/organisms/post-card"
import TagsList from "../components/organisms/tags-list"
import { Helmet } from "react-helmet"
import HatenaArticles from "../components/organisms/hatena-articles"
import ArticleCardVertical from "../components/molecules/article-card-vertical"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx

  const sideBarContents = [<Profile />, <HatenaArticles />, <TagsList />]

  return (
    <Layout location={location} sideBarContents={sideBarContents}>
      <Seo title={`${tag}タグの記事一覧`} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box my={4} mb={4} fontSize="lg">
        <Box as="span" fontSize="3xl" mr={4}>
          {tag}
        </Box>
        {`タグの記事一覧`}

        <Flex justifyContent="right" mb={2} fontSize="sm">
          <Text>{`${totalCount} 件`}</Text>
        </Flex>
      </Box>
      <Grid
        templateColumns={{
          base: "repeat(1,1fr)",
          md: "repeat(2,1fr)",
        }}
        gap="2em"
        justify="center"
      >
        {edges.map((edge) => {
          const post = edge.node
          const title = post.frontmatter.title || "タイトルなし"
          const slug = post.frontmatter.slug
          const created = post.frontmatter.created
          const lastModified = post.frontmatter.modified_date[0] || ""
          const description = post.frontmatter.description || post.excerpt
          const tags = post.frontmatter.tags || [""]
          const ogpImage =
            post.frontmatter.thumbnail_image &&
            post.frontmatter.thumbnail_image.childImageSharp.gatsbyImageData
          return (
            <GridItem mb="1rem">
              <ArticleCardVertical
                title={title}
                slug={slug}
                created={created}
                lastModified={lastModified}
                description={description}
                tags={tags}
                ogpImage={ogpImage}
              ></ArticleCardVertical>
            </GridItem>
          )
        })}
      </Grid>
      {/* <ol style={{ listStyle: `none` }}>
        {edges.map((edge) => {
          const post = edge.node
          const title = post.frontmatter.title || "タイトルなし"
          const slug = post.frontmatter.slug
          const created = post.frontmatter.created
          const lastModified =
            post.frontmatter.modified_date[0] || post.frontmatter.created
          const description = post.frontmatter.description || post.excerpt
          const articleTags = post.frontmatter.tags || [""]

          return (
            <PostCard
              title={title}
              slug={slug}
              created={created}
              lastModified={lastModified}
              description={description}
              tags={articleTags}
            />
          )
        })}
      </ol> */}
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { frontmatter: { tags: { in: [$tag] } } }
      sort: { fields: frontmatter___created, order: DESC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            modified_date(formatString: "YYYY/M/DD")
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(width: 240, height: 126, layout: FIXED)
              }
            }
            created(formatString: "YYYY/M/DD")
            tags
            category
            description
            slug
          }
        }
      }
    }
  }
`
